import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function Properties({ csvData }) {
  const [Env, setEnv] = useState("");
  const [Serial, setSerial] = useState("");
  const [Version, setVersion] = useState("");
  const [Recorded, setRecorded] = useState("");

  function parseProperties() {
    if (csvData) {
      // Split data into lines
      const lines = csvData.split("\n");

      setEnv(lines[0].split(",")[1]);
      setSerial(lines[1].split(",")[1]);
      setVersion(lines[2].split(",")[1]);
      setRecorded(lines[3].split(",")[1]);

      // console.log(Env);
      // console.log(Serial);
      // console.log(Version);
      // console.log(Recorded);
    }
  }

  useEffect(() => {
    parseProperties();
  }, [csvData]);

  const style = {
    width: 100,
    maxWidth: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderStyle: "border-box"
  };


  if (!csvData) return null;

  return (
    <Box
      sx={{
        marginTop: 2,
        display: "flex",
        // backgroundColor:'red'
      }}
    >
      <TableContainer component={Paper}>
      <Typography
          // sx={{ flex: '1 1 100%' }}
          variant="h6"
          sx={{
            marginLeft: 2,
            marginTop: 2,
          }}
        >
          Properties
        </Typography>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell style={style}>Environment</TableCell>
              <TableCell style={style}>Serial</TableCell>
              <TableCell style={style}>Version</TableCell>
              <TableCell style={style}>Recorded</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={0}>
              <TableCell>{Env}</TableCell>
              <TableCell>{Serial}</TableCell>
              <TableCell>{Version}</TableCell>
              <TableCell>{Recorded}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Properties;
