import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
function Header({csvFile, setCsvFile, setCsvData}) {
    

    function loadFile() {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = ".csv";
        input.setCustomValidity("Please select a CSV file");
        input.onchange = (event) => {
            setCsvFile(event.target.files[0]);
            event.target.files[0].text().then((text) => {
                setCsvData(text);
            });
        };
        input.click();
    }
    

  return (
    <Box
    sx={{
        display: "flex",
        backgroundColor: "green",
    }}>
      <AppBar
        position="static"
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" color="white">
            IF7 Trace Viewer
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" color="white">
              {csvFile ? `Viewing File: ${csvFile.name}` : "No file selected"}
            </Typography>
            <Button
              sx={{
                marginLeft: 2,
              }}
              color="inherit"
              variant="contained"
              onClick={ loadFile}
            >
              Load
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
