import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DetailDialog from "./DetailDialog";

function ResultsTable({ csvData }) {
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickRow = (row) => {
    setSelectedRow(row);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const cols = ["#", "Type", "Action", "Process", "Hostname"];

  function parseCSV() {
    if (csvData) {
      const lines = csvData.split("\n");
      const headers = lines[5].split(",");

      const data = lines.slice(6).map((line) => {
        const values = line.split(",");
        return headers.reduce((obj, header, index) => {
          obj[header.replace(/"/g, "")] = `${values[index]}`.replace(/"/g, "");
          return obj;
        }, {});
      });
      setRows(data.slice(0, -1));
      //  console.log(rows);
    }
  }

  useEffect(() => {
    parseCSV();
  }, [csvData]);

  const style = {
    // width: 100,
    // maxWidth: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderStyle: "border-box",
  };

  if (!csvData) return null;

  return (
    <Box
      sx={{
        marginTop: 2,
        overflowX: "initial",
        overflowY: "hidden"
      }}
    >
      <TableContainer component={Paper} sx={{maxHeight:'100%'}}>
        <Typography
          variant="h6"
          sx={{
            marginLeft: 2,
            marginTop: 2,
          }}
        >
          Results
        </Typography>
        <Table stickyHeader  sx={{ minWidth: 650}}>
          <TableHead >
            <TableRow>
              {cols.map((header) => (
                <TableCell>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow 
              key={row["#"]}
              onClick={() => handleClickRow(row)} style={{ cursor: 'pointer' }}
              >
                <TableCell
                  sx={{
                    maxWidth: 10,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    borderStyle: "border-box",
                  }}
                >
                  {row["#"]}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 60,
                  }}
                >
                  {row["Type"]}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 100,
                  }}
                >
                  {row["Action"]}
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: 80,
                  }}
                >
                  {row["Process"]}
                </TableCell>
                <TableCell>{row["Hostname"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedRow && selectedRow["Hostname"]}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DetailDialog selectedRow={selectedRow} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>

     
    </Box>
  );
}

export default ResultsTable;
