import {
  Box,
  Typography,
} from "@mui/material";

function DetailDialog({ selectedRow }) {
  if (!selectedRow) return null;

  return (
    <Box
      sx={{
        maxWidth: 800,
      }}
    >
      <DetailRow Key="Type" Value={selectedRow.Type} />
      <DetailRow Key="Action" Value={selectedRow.Action} />
      <DetailRow Key="Status" Value={selectedRow.Status} />
      <DetailRow Key="Protocol" Value={selectedRow.Protocol} />
      <DetailRow Key="Hostname" Value={selectedRow.Hostname} />
      <DetailRow Key="URL" Value={selectedRow.URL} />
      <DetailRow Key="Content Type" Value={selectedRow["Content Type"]} />
      <DetailRow Key="Process" Value={selectedRow.Process} />
      <DetailRow Key="Classification" Value={selectedRow.Classification} />
      <DetailRow Key="Matching Rule" Value={selectedRow["Matching Rule"]} />
    </Box>
  );
}

function DetailRow({ Key, Value }) {
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Typography
        sx={{
          fontWeight: "bold",
          marginBottom: 0.7,
        }}
      >
        {Key}:{" "}
      </Typography>
      <Box
      sx={{
        // width: 300,
        // maxHeight: 400,
        wordBreak: "break-all",
        overflow: "auto",
      }}>
        <Typography
          sx={{
            marginLeft: 1,
          }}
        >
          {Value}
        </Typography>
      </Box>
    </Box>
  );
}

export default DetailDialog;
