import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import Header from "./Components/Header";
import Properties from "./Components/Properties";
import ResultsTable from "./Components/ResultsTable";

function App() {
  const [csvFile, setCsvFile] = useState(null);
  const [csvData, setCsvData] = useState(null);

  // useEffect(() => {

  //   console.log(csvData);
  // }, [csvData]);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow:"hidden"
      }}
    >
      <Header
        csvFile={csvFile}
        setCsvFile={setCsvFile}
        setCsvData={setCsvData}
      />
      <Properties csvData={csvData} />
      <ResultsTable csvData={csvData} />
    </Box>
  );
}

export default App;
